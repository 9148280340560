$darkGrey: #707070;
$white: #f4f4f4;
$red: #f95c4f;
$grey: #606060;
$darkGrey: #787878;

$mobile-width-min: 320px;
$mobile-width-max: 415px;
$tablet-width-min: 416px;
$tablet-width-max: 1025px;
$desktop-width-min: 1367px;
$desktop-width-max: 1920px;

@mixin mobile {
	@media (min-width: #{$mobile-width-min}) and (max-width: #{$mobile-width-max - 1px}) {
		@content;
	}
}
@mixin tablet {
	@media (min-width: #{$tablet-width-min}) and (max-width: #{$tablet-width-max - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-width-min}) {
		@content;
	}
}
@mixin responsive {
	@media (min-width: #{$mobile-width-min}) and (max-width: #{$tablet-width-max}) {
		@content;
	}
}