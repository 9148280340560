@import "../../styles/variables.scss";

.login_page {
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	background: #F1F1F1;

	.login_form_container{
		width: 100%;
		max-width: 800px;
		margin: 10em auto;
		  .login-form {
			background: #fff;
			width: 100%;
			padding: 20px;
			max-width: 414px;
			border-radius: 5px;
			margin: 0 auto;
			font-size:16px !important;

			.ant-form-item{
				font-size:16px !important;
			}
			.ant-input {
				font-size:16px !important;

			}
		  }
		  .login-form-forgot {
			float: right;
		  }
		  .login-form-button {
			width: 100%;
		  }
	}
}
