@import "~antd/dist/antd.css";
@import "../styles/variables.scss";
body,
html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// overflow: scroll;
	height: 100%;
	width: 100%;
	
}

// ::-webkit-scrollbar {
// 	width: 0px;
// 	background: transparent; /* make scrollbar transparent */
// }
// @include tablet {
// 	body,
// 	html {
// 		font-size: 12px !important;
// 	}
// }

// @include desktopSmall {
// 	body,
// 	html {
// 		font-size: 12px !important;
// 	}
// }
// @include desktopMedium {
// 	body,
// 	html {
// 		font-size: 14px !important;
// 	}
// }
// @include desktop {
// 	body,
// 	html {
// 		font-size: 16px !important;
// 	}
// }