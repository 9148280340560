@import "../../styles/variables.scss";
.dashboard_layout{
    height:100vh;
    .ant-layout-content{
      background: white !important;
      margin: 16px;
      padding:0;
      overflow: auto;
    }
    .trigger {
      display: none;
      font-size: 18px;
      line-height: 64px;
      padding: 0 24px;
      cursor: pointer;
      transition: color 0.3s;
    }
    
    .trigger:hover {
      color: #1890ff;
    }
   
    .logo {
      height: 3em;
      // background: rgba(255, 255, 255, 0.2);
      margin: 1.5em;
      .cls-2{
        fill:$grey
      }
     
    } 
    .loading_div{
      text-align: center;
     
      // background: rgba(0, 0, 0, 0.05);
      padding: 0 ;
      margin: 0px; 
    }
    @include responsive{
      .ant-layout-content{
        margin:0
      }
      .ant-layout-sider{
        width:50px !important;
        min-width:50px !important;
        max-width: 50px !important;
        .ant-menu-inline-collapsed{
          width:50px !important;
        }
        .ant-menu-item{
          padding:0 16px !important;
          span{
            display: none
          }
        }
      }
      .logo-svg {
         display: none
      } 
     }
}