@import "../../styles/variables.scss";

#pages_container{
    height: 100%;
    .ant-tabs{
        // height: 100%;
        .ant-tabs-top-content {
            padding : 0 12px;
            height: 100%;
        }
    }
    .pages_content{
        border-radius: 4px;
        // overflow: auto;
        // position: absolute;
        top: 4em;
        bottom: 0;
        // height:75vh ;
        width:100%;
        .section_image_card{
            width: 104px;
            // height: 104px;
            .ant-card-body{
                padding:5px 0
            }
            .list_image_div{
                width: 100%;
                background:red;
                background-color: #cccccc; /* Used if the image is unavailable */
                height: 50px; /* You must set a specified height */
                background-position: center; /* Center the image */
                background-repeat: no-repeat; /* Do not repeat the image */
                background-size: cover; /* Resize the background image to cover the entire container */
               }
        }
      
        .editForm{
            .ant-form-item{
                margin:0
            }
            .ant-form-item-label{
                text-align: left
            }
            .ant-form-item-control {
                line-height: 25px
            }
            .ant-input-group-addon{
                padding:0;
                background: white;
                border:none
            }
            .ant-upload{
                padding:0
                
            }
            .ant-upload.ant-upload-select-picture-card{
                margin-right:0
            }
            .ant-input {
                margin-left:10px;
                border-top:0;
                border-left:0;
                border-right: 0;
                border-radius:0
            }
            .ant-input:focus{
                border-color: #40a9ff;
                border-right-width: 1px !important;
                outline: 0;
                -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
                box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
            }
            .ant-input-sm {
                height: 24px;
                padding: 1px 0px;
            }            
        }
    
        .ant-list {
        width:80%;
        .ant-list-item{
            min-height:100px;
            padding:0;
        }
        }
        .ant-list-item-meta-avatar{
            margin-right: 16px
        }
        .ant-list-item-meta-title{
            margin-bottom:0
            
        }
        .ant-list-item-meta-description{
            line-height: 1.2em;
            padding-top:5px;
            p{
                span{
                    color:#4e4e4e;
                    margin-right:5px;
                }
                margin-bottom:5px;
                // width:80%;
                font-size: 1em;
            }
        }
       
    }
    @include responsive{
        .ant-tabs-top-content {
            padding : 0 5px !important;
        }
        .ant-list{
            width:100% !important;;
            .ant-list-item-meta-avatar{
                margin-right:5px !important;
            }
            .ant-upload.ant-upload-select-picture-card
            {           
                width:50px;
            }            
            .section_image_card{
                width:75px;
                .ant-card-body{
                    font-size: .7rem;
                }
            }
            .ant-list-item-meta-description{
                font-size: .7rem;

            }
        }
    }
}